import { createComponent } from '@/helpers/alpine';
import { DataLayer } from '@/services/datalayer';
import { RecaptchaJsAPI } from '@/services/recaptcha';

declare let psemailsubscription_subscription: string;

export const RecaptchaEmailSubscription = {
    // Activation du champ newsletter si le javacript est activé
    load(selector = '.newsletter-recaptcha-form') {
        const form = document.querySelector<HTMLFormElement>(selector);

        if (form) {
            const newsletterInput =
                form.querySelector<HTMLFormElement>(`[name$="email"]`);

            const newsletterButton =
                form.querySelector<HTMLFormElement>(`button`);

            if (newsletterInput) {
                newsletterInput.disabled = false;
            }

            if (newsletterButton) {
                newsletterButton.disabled = false;
            }
        }

        const formBlog = document.querySelector<HTMLFormElement>(
            '.newsletter-recaptcha-form-blog',
        );

        if (formBlog) {
            const newsletterInputBlog =
                formBlog.querySelector<HTMLFormElement>(`[name$="email"]`);

            const newsletterButtonBlog =
                formBlog.querySelector<HTMLFormElement>(`button`);

            if (newsletterInputBlog) {
                newsletterInputBlog.disabled = false;
            }

            if (newsletterButtonBlog) {
                newsletterButtonBlog.disabled = false;
            }
        }
    },
};

export default createComponent(() => ({
    email: '',
    message: '',
    loading: false,
    submitted: false,
    error: undefined as boolean | undefined,

    async submitData(event: SubmitEvent) {
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);

        this.message = '';
        this.error = undefined;
        this.loading = true;

        try {
            const recaptchaSiteKey = formData.get('recaptchaSiteKey') as string;

            if (!recaptchaSiteKey) {
                throw new Error('reCAPTCHA site key not found');
            }

            RecaptchaJsAPI.load();
            await RecaptchaJsAPI.ready;

            const token = await grecaptcha.execute(recaptchaSiteKey, {
                action: 'submitNewsletter',
            });

            formData.set('recaptchaToken', token);

            const response = await fetch(psemailsubscription_subscription, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            this.email = data.value;
            this.message = data.msg;
            this.error = !!data.nw_error;
            this.submitted = true;

            if (!this.error) {
                DataLayer.push({ event: 'newsletter_subscription' });
            }
        } catch (e) {
            console.error('An error occurred:', e);
            this.error = true;
            this.message = "Une erreur s'est produite. Veuillez réessayer.";
        } finally {
            this.loading = false;
        }
    },
}));
